// Methods for fetching data from the new JVR LMS
import { jvrLmsCfEndpoint, jvrLmsCfEndpointCompleteCourse, jvrLmsEndpoint } from './config';

export const fetchLmsData = async (hUserId, languageId) => {
  try {
    // Add the language id from the authenticated user's preferences to this query
    const response = await fetch(`${jvrLmsCfEndpoint}&resource=students/${hUserId}/courses?language=${languageId}`, {
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}, ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export const fetchCourseData = async (courseId) => {
  try {
    // Add the language id from the authenticated user's preferences to this query
    const response = await fetch(`${jvrLmsCfEndpoint}&resource=courses/${courseId}`, {
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}, ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export const renderCourseVideo = (course) => {
  return `
    <div class="jvrLmsCourseContainer">
      <div class="courseHeaderContainer">
        <div>
          <h5>${course.data.attributes.name}</h5>
          <p>${course.data.attributes.description}</p>
        </div>        
        <svg class="closeCourseIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </div>      
      <div class="vimeoContainer">
        ${course.data.relationships.video.data.embedCode}
      </div>      
    </div>
`;
}

export const includeVimeoPlayerScript = () => {
  const script = document.createElement('script');
  script.src = "https://player.vimeo.com/api/player.js";
  document.head.append(script);
}

const completeCourse = async (requestBody) => {
    try {
      const response = await fetch(`${jvrLmsCfEndpointCompleteCourse}&resource=students/courses/completed&user=${requestBody.data.attributes.hUserId}&course=${requestBody.data.attributes.course.id}&language=${requestBody.data.attributes.course.languageId}`, {
        headers: {
          'Accept': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}, ${response.statusText}`);
      }

      return response.json();
    } catch (error) {
      console.log(error);
    }
}

export const vimeoPlayerControls = (userData, courseData) => {
     const iframe = document.querySelector('.vimeoContainer').firstElementChild;
     const vimeoPlayer = new Vimeo.Player(iframe);

      const requestBody = {
        "data": {
          "attributes": {
            "hUserId": userData.HuserID,
            "course": {
              "id": courseData.data.attributes.igCourseId,
              "languageId": userData.languageID
            }
          }
        }
      };

      const videoEnded = async () => {
       await completeCourse(requestBody);
      };
     
    vimeoPlayer.on('ended', videoEnded);
}

// Create a new user in the LMS and connect them to their assigned courses
export const createUserInLms = async (hUserId) => {
  try {
    const response = await fetch(`${jvrLmsEndpoint}registerUserInLms&user=${hUserId}`, {
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}, ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export const getAllCourses = async () => {
  try {
    const response = await fetch(`${jvrLmsCfEndpoint}&resource=courses`, {
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}, ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export const assignUserToCourse = async (userId, courseId) => {
  try {
    const response = await fetch(`${jvrLmsEndpoint}addUserToCourse&studentId=${userId}&courseId=${courseId}`, {
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}, ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export const assignUserToCourseWithId = async (userId, courseId) => {
  try {
    const response = await fetch(`${jvrLmsEndpoint}addUserToCourseWithId&studentId=${userId}&courseId=${courseId}`, {
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}, ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export const addUsersToCoursesWhenReportUploaded = async (applicationId, courseId) => {
  try {
    const response = await fetch(`${jvrLmsEndpoint}reportsCourseAllocation&applicationId=${applicationId}&courseId=${courseId}`, {
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}, ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.log(error);
  }
}